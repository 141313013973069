import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";

const Impressum = () => {
  return (
    <div>
      <Navigation />
      <section id="impressum">
        <h1>IMPRESSUM</h1>
        <h2>Angaben gemäß § 5 TMG:</h2>
        <p>Eileen Baum</p>
        <h2>Streitschlichtung</h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <h3>HAFTUNG FÜR INHALTE</h3>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
          Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
          hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
          Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
          Inhalte umgehend entfernen.
        </p>
        <h3>HAFTUNG FÜR LINKS</h3>
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren
        Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
        Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
        Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
        Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
        waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
        inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
        von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        <h3>URHEBERRECHT</h3>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
        sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
        werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Inhalte umgehend entfernen.
        <h1>Site Notice</h1>
        <h2>
          Information provided according to Sec. 5 German Telemedia Act (TMG):
        </h2>
        <p>Eileen Baum</p>
        <h2>Dispute resolution</h2>
        <p>
          We do not take part in online dispute resolutions at consumer
          arbitration boards.
        </p>
        <h3>LIABILITY FOR CONTENTS</h3>
        As service providers, we are liable for own contents of these websites
        according to Sec. 7, paragraph 1 German Telemedia Act (TMG). However,
        according to Sec. 8 to 10 German Telemedia Act (TMG), service providers
        are not obligated to permanently monitor submitted or stored information
        or to search for evidences that indicate illegal activities. Legal
        obligations to removing information or to blocking the use of
        information remain unchallenged. In this case, liability is only
        possible at the time of knowledge about a specific violation of law.
        Illegal contents will be removed immediately at the time we get
        knowledge of them.
        <h3>LIABILITY FOR LINKS</h3>
        <p>
          Our offer includes links to external third party websites. We have no
          influence on the contents of those websites, therefore we cannot
          guarantee for those contents. Providers or administrators of linked
          websites are always responsible for their own contents. The linked
          websites had been checked for possible violations of law at the time
          of the establishment of the link. Illegal contents were not detected
          at the time of the linking. A permanent monitoring of the contents of
          linked websites cannot be imposed without reasonable indications that
          there has been a violation of law. Illegal links will be removed
          immediately at the time we get knowledge of them.
        </p>
        <h3>COPYRIGHT</h3>
        <p>
          Contents and compilations published on these websites by the providers
          are subject to German copyright laws. Reproduction, editing,
          distribution as well as the use of any kind outside the scope of the
          copyright law require a written permission of the author or
          originator. Downloads and copies of these websites are permitted for
          private use only. The commercial use of our contents without
          permission of the originator is prohibited. Copyright laws of third
          parties are respected as long as the contents on these websites do not
          originate from the provider. Contributions of third parties on this
          site are indicated as such. However, if you notice any violations of
          copyright law, please inform us. Such contents will be removed
          immediately.
        </p>
      </section>
      <Footer />
    </div>
  );
};

export default Impressum;
